<template>
  <div class="prime-scroll-container">
    <ion-grid class="prime-enrollment-grid pad-thirty">
      <ion-row>
        <ion-col class="margin-top-twenty">
          <img class="prime-logo-enrollment" src="../assets/logo.svg" />
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="margin-top-twenty">
          <h3>Ask your doctor questions anytime and anywhere.</h3>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="text-charcoal text-med">
          <p>Don't wait for your next visit! Get the answers you need, when you need them.</p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="margin-top-ten">
          <h4>Some things to know:</h4>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-row class="ion-align-items-center">
            <ion-col size="auto">
              <ion-icon class="prime-green-check icon-xlarge" name="ios-checkmark"></ion-icon>
            </ion-col>
            <ion-col class="text-charcoal text-med">
              Your participation is voluntary.
            </ion-col>
          </ion-row>
          <ion-row class="ion-align-items-center">
            <ion-col size="auto">
              <ion-icon class="prime-green-check icon-xlarge" name="ios-checkmark"></ion-icon>
            </ion-col>
            <ion-col class="text-charcoal text-med">
              You can easily opt out at any time by texting "STOP".
            </ion-col>
          </ion-row>
          <ion-row class="ion-align-items-center">
            <ion-col size="auto">
              <ion-icon class="prime-green-check icon-xlarge" name="ios-checkmark"></ion-icon>
            </ion-col>
            <ion-col class="text-charcoal text-med">
              For more sensitive conversations that you may not want stored in your text message history, you can use our secure messaging portal by texting the keyword "Secure".
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="margin-top-twenty">
          <p class="text-charcoal">Please see our <span class="link-dummy" @click="goToNamedRoute('CompliancePublic', 'End User License Agreement', 's98h7z')">End User License Agreement</span>, and <span class="link-dummy" @click="goToNamedRoute('CompliancePublic', 'Privacy Policy', 'cr7vv7')">CCPA Privacy Notice</span> for more information.</p>
        </ion-col>
      </ion-row>
      <ion-row v-if="this.showInstructions" class="margin-top-ten">
        <ion-col>
          <h4>Return to your text messaging app and reply "Agree" to get started using LumerisEngage!</h4>
        </ion-col>
      </ion-row>
      <ion-row class="margin-top-fifty">
        <ion-col>
          <footerCompliance></footerCompliance>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { checkmark } from "ionicons/icons";
import FooterCompliance from "@/components/Global/FooterCompliance";

addIcons({
  "ios-checkmark": checkmark.ios
});

export default {
  name: "EnrollmentInfo",
  components: {
    FooterCompliance
  },
  created() {
    this.showInstructions = this.$route.query.linked;
  },
  methods: {
    goToNamedRoute(routeName, title, contentCode) {
      this.$router.push({
        name: routeName,
        params: { pageTitle: title },
        query: { type: contentCode }
      });
    }
  }
};
</script>

<style>
.prime-scroll-container {
  height: 100%;
  overflow-y: auto;
}
img.prime-logo-enrollment {
  width: 280px;
}

.prime-green-check {
  fill: green;
}

@media screen and (min-width: 940px) {
  .prime-enrollment-grid {
    width: 720px;
    margin: 0 auto;
  }
}
</style>
